export const BUTTON_VARIANT = {
  PRIMARY: 'btn-primary',
  SECONDARY: 'btn-secondary',
  TERTIARY: 'btn-tertiary',
  DANGER: 'btn-danger',
  INFO: 'btn-info',
  WHITE: 'btn-white',
  WARNING: 'btn-warning',
} as const;

export const BUTTON_TYPE = {
  BUTTON: 'button',
  SUBMIT: 'submit',
} as const;

export const BUTTON_SIZE = {
  NONE: 'none',
  SMALL: 'btn-sm',
  MEDIUM: 'btn-md',
  LARGE: 'btn-lg',
} as const;

export const BUTTON_WIDTH_BREAKPOINTS = {
  xxs: 'xxs:min-w-[62px]',
  '@xxs': '@xxs:min-w-[62px]',
  xs: 'xs:min-w-[62px]',
  '@xs': '@xs:min-w-[62px]',
  sm: 'sm:min-w-[62px]',
  '@sm': '@sm:min-w-[62px]',
  md: 'md:min-w-[62px]',
  '@md': '@md:min-w-[62px]',
  lg: 'lg:min-w-[62px]',
  '@lg': '@lg:min-w-[62px]',
  xl: 'xl:min-w-[62px]',
  '@xl': '@xl:min-w-[62px]',
  '2xl': '2xl:min-w-[62px]',
  '@2xl': '@2xl:min-w-[62px]',
  '4xl': '4xl:min-w-[62px]',
  '@4xl': '@4xl:min-w-[62px]',
  desktop: 'desktop:min-w-[62px]',
  '@desktop': '@desktop:min-w-[62px]',
};

export const BUTTON_TEXT_BUTTON_BREAKPOINTS = {
  xxs: 'max-xss:hidden',
  '@xxs': '@max-xss:hidden',
  xs: 'max-xs:hidden',
  '@xs': '@max-xs:hidden',
  sm: 'max-sm:hidden',
  '@sm': '@max-sm:hidden',
  md: 'max-md:hidden',
  '@md': '@max-md:hidden',
  lg: 'max-lg:hidden',
  '@lg': '@max-lg:hidden',
  xl: 'max-xl:hidden',
  '@xl': '@max-xl:hidden',
  '2xl': 'max-2xl:hidden',
  '@2xl': '@max-2xl:hidden',
  '4xl': 'max-4xl:hidden',
  '@4xl': '@max-4xl:hidden',
  desktop: 'max-desktop:hidden',
  '@desktop': '@max-desktop:hidden',
} as const;

export const BUTTON_ICON_BREAKPOINTS = {
  xxs: 'xxs:hidden',
  '@xxs': '@xxs:hidden',
  xs: 'xs:hidden',
  '@xs': '@xs:hidden',
  sm: 'sm:hidden',
  '@sm': '@sm:hidden',
  md: 'md:hidden',
  '@md': '@md:hidden',
  lg: 'lg:hidden',
  '@lg': '@lg:hidden',
  xl: 'xl:hidden',
  '@xl': '@xl:hidden',
  '2xl': '2xl:hidden',
  '@2xl': '@2xl:hidden',
  '4xl': '4xl:hidden',
  '@4xl': '@4xl:hidden',
  desktop: 'desktop:hidden',
  '@desktop': '@desktop:hidden',
} as const;
