<script setup lang="ts" name="BaseButton">
import { computed, useSlots } from 'vue';
import type { RouteLocationRaw } from 'vue-router';
import { useLink } from 'vue-router';

import {
  BUTTON_ICON_BREAKPOINTS,
  BUTTON_SIZE,
  BUTTON_TEXT_BUTTON_BREAKPOINTS,
  BUTTON_TYPE,
  BUTTON_VARIANT,
  BUTTON_WIDTH_BREAKPOINTS,
} from '~/constants';
const {
  href,
  icon,
  label,
  hideLabelAtBreakpoint,
  roundedFull,
  iconSize = '2xl',
  to = '',
  description,
  loading = false,
  inverted = false,
  disabled = false,
  tooltipDescription = '',
  skeletonLoading = false,
  dataTest = 'g2-button',
  size = BUTTON_SIZE.MEDIUM,
  variant = BUTTON_VARIANT.PRIMARY,
  type = BUTTON_TYPE.BUTTON,
} = defineProps<{
  href?: string;
  target?: HTMLAnchorElement['target'];
  label?: string;
  icon?: IconType;
  loading?: boolean;
  dataTest?: string;
  tooltipDescription?: string;
  inverted?: boolean;
  variant?: IButtonVariant;
  size?: IButtonSize;
  disabled?: boolean;
  description?: string;
  to?: RouteLocationRaw;
  skeletonLoading?: boolean;
  type?: IButtonType;
  roundedFull?: boolean;
  iconSize?: IconSize;
  hideLabelAtBreakpoint?: IButtScreenBreakpoints;
}>();
const emit = defineEmits<{
  (e: 'click'): void;
}>();

import Icon from '~/components/Icon.vue';
import Tooltip from '~/components/Tooltip.vue';

import type {
  IButtonSize,
  IButtonType,
  IButtonVariant,
  IButtScreenBreakpoints,
  IconSize,
  IconType,
} from '~/types';

const slots = useSlots();

const { navigate } = useLink({ to });

const isDisabled = computed(() => disabled && !skeletonLoading);
const isLoading = computed(() => skeletonLoading);

const isTextButton = computed<boolean>(
  () => !!label || (slots.default?.()?.length || 0) > 0,
);

const buttonWidth = computed(() => {
  if (hideLabelAtBreakpoint && isTextButton.value) {
    return `min-w-[40px] ${BUTTON_WIDTH_BREAKPOINTS[hideLabelAtBreakpoint]}`;
  }

  if (isTextButton.value) {
    return 'min-w-[62px]';
  }

  return 'min-w-[40px]';
});

const hideButtonIcon = computed(
  () => hideLabelAtBreakpoint && BUTTON_ICON_BREAKPOINTS[hideLabelAtBreakpoint],
);

const hideTextButton = computed(
  () =>
    hideLabelAtBreakpoint &&
    BUTTON_TEXT_BUTTON_BREAKPOINTS[hideLabelAtBreakpoint],
);

const tag = computed(() => {
  if (disabled || loading || to) return 'button';

  if (href) return 'a';

  return 'button';
});

const classesByState = computed(() => {
  if (isDisabled.value && !inverted) {
    return 'btn-disabled';
  }

  if (isDisabled.value && inverted) {
    return 'btn-disabled-inverted';
  }

  if (isLoading.value) {
    return 'btn-loading';
  }

  return inverted
    ? `g2-btn ${variant} btn-inverted`
    : `g2-btn ${variant} shadow-sm hover:shadow-lg`;
});

const showTooltip = (show: () => void) => {
  if (tooltipDescription) {
    show();
  }
};

const hideTooltip = (hide: () => void) => {
  if (tooltipDescription) {
    hide();
  }
};

const onClick = () => {
  if (disabled || loading) return;

  if (to) {
    navigate();
  }

  emit('click');
};
</script>

<template>
  <Tooltip :description="tooltipDescription" placement="bottom">
    <template #trigger="{ show, hide, setRef }">
      <component
        v-bind="$attrs"
        :ref="setRef"
        :is="tag"
        :to="to"
        :target="target"
        :href="href"
        v-tooltip="description"
        :type="type"
        :data-test="dataTest"
        :disabled="disabled"
        @click="onClick"
        @mouseover="showTooltip(show)"
        @mouseleave="hideTooltip(hide)"
        class="flex-center text-button-1 uppercase tracking-widest focus:outline-none focus-visible:ring-2 focus-visible:ring-ocean-3"
        :class="[
          size,
          classesByState,
          buttonWidth,
          roundedFull ? 'rounded-full' : 'rounded-lg',
        ]"
      >
        <Icon v-if="loading" icon="mdi:loading" class="animate-spin" />

        <template v-else>
          <Icon
            v-if="icon && isTextButton"
            :class="[hideTextButton]"
            :icon="icon"
            :size="iconSize"
            class="mr-2"
          />

          <Icon
            v-if="(!isTextButton || hideLabelAtBreakpoint) && icon"
            :class="[hideButtonIcon]"
            data-testid="button-icon"
            :icon="icon!"
            :size="iconSize"
          />

          <span v-if="isTextButton" :class="[hideTextButton]">
            <slot>{{ label }}</slot>
          </span>

          <div
            v-if="slots.rightIcon"
            class="ml-3 flex justify-center"
            :class="[hideTextButton]"
          >
            <slot name="rightIcon" />
          </div>
        </template>
      </component>
    </template>
  </Tooltip>
</template>

<style scoped>
.btn-primary {
  --btn-background: theme('colors.primary.8');
  --btn-foreground: theme(colors.white);
  --btn-background-hover: theme(colors.primary.6);
  --btn-foreground-hover: theme('colors.white');

  --inverted-foreground-color: theme('colors.primary.8');
  --inverted-foreground-hover: theme('colors.primary.6');
}

.btn-secondary {
  --btn-background: theme('colors.white');
  --btn-foreground: theme('colors.primary.8');
  --btn-background-hover: theme('colors.white');
  --btn-foreground-hover: theme('colors.ocean.6');

  --inverted-foreground-color: theme('colors.primary.8');
  --inverted-foreground-hover: theme('colors.ocean.6');
}

.btn-tertiary {
  --btn-background: transparent;
  --btn-foreground: theme('colors.charcoal.6');
  --btn-background-hover: transparent;
  --btn-foreground-hover: theme('colors.primary.8');

  --inverted-foreground-color: theme('colors.charcoal.6');
  --inverted-foreground-hover: theme('colors.primary.8');
}

.btn-danger {
  --btn-background: theme('colors.red.6');
  --btn-foreground: theme('colors.white');
  --btn-background-hover: theme('colors.red.4');
  --btn-foreground-hover: theme('colors.white');

  --inverted-foreground-color: theme('colors.red.6');
  --inverted-foreground-hover: theme('colors.red.4');
}

.btn-info {
  --inverted-foreground-color: theme('colors.indigo.8');
  --inverted-foreground-hover: theme('colors.indigo.4');
}

.btn-warning {
  --inverted-foreground-color: theme('colors.orange.3');
  --inverted-foreground-hover: theme('colors.orange.7');
}

.btn-white {
  --inverted-foreground-color: theme('colors.white');
  --inverted-foreground-hover: theme('colors.primary.8');
}

.g2-btn {
  background-color: var(--btn-background);
  color: var(--btn-foreground);
}

.g2-btn:hover {
  background-color: var(--btn-background-hover);
  color: var(--btn-foreground-hover);
}

.g2-btn.btn-inverted {
  --btn-background: transparent;
  color: var(--inverted-foreground-color);
}

.g2-btn:hover.btn-inverted {
  color: var(--inverted-foreground-hover);
  --btn-background-hover: transparent;
}

.g2-btn:focus.btn-inverted {
  background-color: transparent;
  color: var(--inverted-foreground-color);
}

.btn-sm {
  height: 32px;
  padding: 0 16px;
}

.btn-md {
  height: 40px;
  padding: 0 16px;
}

.btn-lg {
  height: 48px;
  padding: 0 32px;
}

.btn-disabled {
  cursor: not-allowed;
  background-color: theme('colors.charcoal.4');
  color: theme('colors.white');
}

.btn-disabled-inverted {
  cursor: not-allowed;
  color: theme('colors.charcoal.4');
}

.btn-loading {
  animation: pulse 2s linear infinite;
  pointer-events: none;
  background-color: theme('colors.charcoal.2');
  color: theme('colors.charcoal.2');
}
</style>
